@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  height: 100%;
  width: 100%;
  font-family: 'Poppins', sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
  
/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Style the audio player */
audio {
  background-color: white; /* Set background color to transparent */
  color: white; /* Set text color to white */
}

/* Style the playback controls */
audio::-webkit-media-controls-panel {
    background-color: transparent; /* Set controls background to transparent */
}

/* Style individual playback controls */
audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-mute-button,
audio::-webkit-media-controls-volume-slider {
    color: white; /* Set controls' symbols color to white */
}
